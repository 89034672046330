import { store } from "@/store/store";
import { message } from "antd";
import router from "next/router";
import { INVALID_TOKEN_ERROR } from "../consts/errors";
import { performClientSideLogout } from "../features/auth/utils/auth.utils";

interface GraphqlError {
  message: string;
  path: string[];
  extension: any;
  location: any;
}

export const handleGraphqlErrors = (errors: GraphqlError[]) => {
  for (const error of errors) {
    let { message: errorMessage } = error;
    switch (error.message) {
      case INVALID_TOKEN_ERROR:
        performClientSideLogout(store.dispatch);
        errorMessage = "Authorization token has expired";
        router.push("/auth/login");
        break;
    }

    if (errorMessage !== "Forbidden") {
      message.error(errorMessage);
    }

    throw new Error(errorMessage);
  }
};
