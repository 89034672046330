import { UnapprovedInvoices } from "@/components/Dashboard/UnapprovedInvoices/UnapprovedInvoices";
import { PageContent } from "@/components/Shared/app/PageContent/PageContent";
import { SimpleGrid } from "@chakra-ui/react";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import React from "react";

export default function Home() {
  return (
    <PageContent>
      <SimpleGrid
        className="Files"
        mt={6}
        columns={{
          md: 2,
        }}
        gap={6}
      >
        <UnapprovedInvoices />
      </SimpleGrid>
    </PageContent>
  );
}

export const getStaticProps = async () => ({
  props: {
    ...(await serverSideTranslations("sk", ["common"])),
  },
});
